a {
  color: cyan;
  text-decoration: none;
}

a:visited {
  color: cyan;
  text-decoration: none;
}


.map-container {
  height: 100vh;
  width: 100vw;
}

.ui-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #fff;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 30px 20px;
  width: 33vw;
  max-width: 380px;
}

.control-box-title {
  background-color: rgba(35, 55, 75, 0.9);
  padding: auto;
  margin: 0;
  border: 1px solid #ccc;
  width: fit-content;
  padding: 12px;
  border-radius: 4px;
}

.control-box {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  box-sizing: border-box;
  padding: 12px 22px 6px 30px;
  font-family: monospace;
  z-index: 1;

  border: 1px solid #ccc;

  border-radius: 4px;
  width: 100%;
}

.legend-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 12px 22px;
  font-family: monospace;
  z-index: 1;

  border: 1px solid #ccc;

  border-radius: 4px;
  width: 100%;
}

.legend-title {
  max-width: 50%;
}

.legend-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}

.legend-color {
  height: 1rem;
  width: 1rem;
}

.MuiSlider-markLabel,
.MuiSlider-mark {
  color: #eee !important;
}

.info-box {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #fff;
  font-family: monospace;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 30px 20px;
  width: 33vw;
  max-width: 380px;
  background-color: rgba(35, 55, 75, 0.9);
  padding: 12px 22px;
}



@media only screen and (max-width: 765px) {

  .ui-wrapper {
    width: unset;
    margin: unset;
    top: 16px;
    left: 12px;
    right: 12px;
  }

  .info-box {
    width: unset;
    margin: unset;
    bottom: 16px;
    left: 12px;
    right: 12px;
  }


  .control-box-title {
    display: none;
  }

}